import React from 'react';
import { router } from 'pages/router';
import { RouterProvider } from 'react-router-dom';
import 'shared/i18n/init-i18n';
import Toaster from '../shared/Toaster';

export const App = () => {
  return (
    <Toaster>
      <RouterProvider router={router}/>
    </Toaster>
  );
};
