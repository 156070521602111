import cn from "classnames";
import { Link } from "react-router-dom";
import s from "./button.module.css";

export const Button = ({
  children,
  href,
  className,
}: {
  children: string;
  href: string;
  className?: string;
}) => {
  return (
    <Link className={cn(s.root, className)} to={href}>
      {children}
    </Link>
  );
};
