import { useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { routes } from "shared/routes";
import s from "./header.module.css";
import logo from "./assets/logo.png";

export const Header = () => {
  const { t } = useTranslation();
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <header className={s.root}>
      <div className={s.left}>
        <button
          className={cn(s.menuButton, { [s.menuButtonOpened]: menuOpened })}
          onClick={() => setMenuOpened((v) => !v)}
        >
          <div />
          <div />
          <div />
        </button>
        <Link to={routes.home}>
          <img alt="logo" src={logo} className={s.logo} />
        </Link>
      </div>
      <nav className={cn(s.navigation, { [s.menuOpened]: menuOpened })}>
        <NavLink className={s.link} to={routes.home}>
          {t("navigation.home")}
        </NavLink>
        <NavLink className={s.link} to={routes.about}>
          {t("navigation.about")}
        </NavLink>
        <NavLink className={s.link} to={routes.services}>
          {t("navigation.services")}
        </NavLink>
        <NavLink className={s.link} to={routes.contactUs}>
          {t("navigation.contactUs")}
        </NavLink>
      </nav>
    </header>
  );
};
