import { useState } from "react";
import cn from "classnames";
import s from "./toggle-content.module.css";

export const ToggleContent = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <div className={cn(s.root, { [s.opened]: opened })}>
      <div className={s.header} onClick={() => setOpened((o) => !o)}>
        <h3 className={s.title}>{title}</h3>
        <div className={s.arrow} />
      </div>
      <div className={s.contentWrapper}>
        <p className={s.content}>{content}</p>
      </div>
    </div>
  );
};
