import { createBrowserRouter } from "react-router-dom";
import { routes } from "shared/routes";
import { HomePage } from "./home-page/home-page";
import { AboutPage } from "./about-page/about-page";
import { ServicesPage } from "./services-page/services-page";
import { ContactUsPage } from "./contact-us-page/contact-us-page";

export const router = createBrowserRouter([
  {
    path: routes.home,
    element: <HomePage />,
  },
  {
    path: routes.about,
    element: <AboutPage />,
  },
  {
    path: routes.services,
    element: <ServicesPage />,
  },
  {
    path: routes.contactUs,
    element: <ContactUsPage />,
  },
]);
