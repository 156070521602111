import { PageContainer } from "shared/ui-kit/page-container/page-container";
import { Footer } from "widgets/footer/footer";
import { Header } from "widgets/header/header";
import s from "./services-page.module.css";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { usePageTitle } from "shared/use-page-title";

export const ServicesPage = () => {
  usePageTitle("services");

  return (
    <>
      <Header />
      <PageContainer>
        <MainSection />
      </PageContainer>
      <Footer />
    </>
  );
};

const MainSection = () => {
  const { t } = useTranslation();

  const getItem = (key: string) => (
    <div className={s.item}>
      <div className={s.itemTitle}>{t(`servicesPage.items.${key}.title`)}</div>
      <p className={s.itemText}>{t(`servicesPage.items.${key}.text`)}</p>
    </div>
  );

  return (
    <section className={s.root}>
      <Fade triggerOnce duration={1500}>
        <h1 className={s.title}>{t("servicesPage.title")}</h1>
        <div className={cn("line", s.line)} />
      </Fade>
      <div className={s.items}>
        <div className={cn(s.row, s.row3items)}>
          <Fade delay={1000} triggerOnce>
            {getItem("development")}
          </Fade>
          <Fade delay={1500} triggerOnce>
            {getItem("marketing")}
          </Fade>
          <Fade delay={2000} triggerOnce>
            {getItem("innovation")}
          </Fade>
        </div>
        <div className={cn(s.row, s.row2items)}>
          <Fade delay={2500} triggerOnce>
            {getItem("advisoty")}
          </Fade>
          <Fade delay={3000} triggerOnce>
            {getItem("deals")}
          </Fade>
        </div>
      </div>
    </section>
  );
};
