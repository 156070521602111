import { useTranslation } from "react-i18next";
import { PageContainer } from "shared/ui-kit/page-container/page-container";
import { Footer } from "widgets/footer/footer";
import { Header } from "widgets/header/header";
import s from "./about-page.module.css";
import cn from "classnames";
import img from "./assets/img.webp";
import { Fade, Slide } from "react-awesome-reveal";
import { usePageTitle } from "shared/use-page-title";

export const AboutPage = () => {
  usePageTitle("about");

  return (
    <>
      <Header />
      <PageContainer>
        <MainSection />
      </PageContainer>
      <Footer />
    </>
  );
};

const MainSection = () => {
  const { t } = useTranslation();

  return (
    <section className={s.root}>
      <Fade triggerOnce duration={1500}>
        <h1 className={s.title}>{t("aboutPage.title")}</h1>
        <div className={cn("line", s.line)} />
      </Fade>
      <section className={s.section}>
        <Slide triggerOnce>
          <article className={s.article}>
            <div>
              <i>{t("aboutPage.text0")}</i> {t("aboutPage.text1")}
            </div>
            <br />
            <div>{t("aboutPage.text2")}</div>
            <br />
            <div>{t("aboutPage.text3")}</div>
          </article>
        </Slide>
        <Slide triggerOnce direction="right">
          <div className={s.imageContainer}>
            <img className={s.image} alt="" src={img} />
          </div>
        </Slide>
      </section>
    </section>
  );
};
