import s from "./footer.module.css";
import logo from "./logo.png";

export const Footer = () => {
  return (
    <footer className={s.root}>
      <section className={s.left}>
        <h3 className={s.title}>© AVSH Marketing</h3>
        <p className={s.p}>
          Suite 1702, Level 17, Boulevard Plaza Tower 1,
          <br />
          Sheikh Mohammed Bin Rashid Boulevard,
          <br />
          Downtown Dubai, United Arab Emirates
        </p>
      </section>
      <img alt="small logo" src={logo} className={s.logo} />
    </footer>
  );
};
