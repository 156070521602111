import { PageContainer } from "shared/ui-kit/page-container/page-container";
import { Footer } from "widgets/footer/footer";
import { Header } from "widgets/header/header";
import s from "./contact-us-page.module.css";
import { useTranslation } from "react-i18next";
import { ContactForm } from "./contact-form/contact-form";
import { usePageTitle } from "shared/use-page-title";

export const ContactUsPage = () => {
  usePageTitle("contactUs");

  return (
    <>
      <Header />
      <PageContainer>
        <MainSection />
      </PageContainer>
      <Footer />
    </>
  );
};

const MainSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className={s.banner}>
        <div className={s.filter} />
        <h1 className={s.title}>{t("contactUsPage.title")}</h1>
        <h4 className={s.subtitle}>{t("contactUsPage.subtitle")}</h4>
      </section>
      <section className={s.form}>
        <ContactForm />
      </section>
    </>
  );
};
