import { useTranslation } from "react-i18next";
import { Header } from "widgets/header/header";
import s from "./home-page.module.css";
import { ReactComponent as Effect } from "./assets/effect.svg";
import { PageContainer } from "shared/ui-kit/page-container/page-container";
import { Button } from "shared/ui-kit/button/button";
import aboutImage from "./assets/about.webp";
import { ToggleContent } from "shared/ui-kit/toggle-content/toggle-content";
import { Footer } from "widgets/footer/footer";
import cn from "classnames";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { usePageTitle } from "shared/use-page-title";
import { routes } from "shared/routes";

export const HomePage = () => {
  usePageTitle("home");

  return (
    <>
      <Header />
      <PageContainer>
        <MainSection />
      </PageContainer>
      <Footer />
    </>
  );
};

const MainSection = () => {
  const { t } = useTranslation();

  const getChooseItem = (key: string) => (
    <div className={s.chooseItem}>
      <div className={s.chooseItemTitle}>
        {t(`homePage.chooseItems.${key}.title`)}
      </div>
      <p className={s.chooseItemText}>
        {t(`homePage.chooseItems.${key}.text`)}
      </p>
    </div>
  );

  return (
    <>
      <section className={s.banner}>
        <div className={s.bg} />
        <div className={s.bg} />
        <div className={s.bg} />
        <div className={s.filter} />
        <Effect className={s.effect} />
        <Slide triggerOnce>
          <h1 className={s.title}>
            {t("homePage.title1")}
            <br />
            {t("homePage.title2")}
          </h1>
        </Slide>
        <Fade triggerOnce direction="right" duration={1500}>
          <h4 className={s.subtitle}>{t("homePage.subtitle")}</h4>
        </Fade>
        <Slide triggerOnce>
          <Button className={s.button} href={routes.contactUs}>
            {t("homePage.button")}
          </Button>
        </Slide>
      </section>
      <section className={s.about}>
        <Fade triggerOnce duration={1500}>
          <h2 className={s.aboutTitle}>{t("homePage.aboutTitle")}</h2>
        </Fade>
        <div className={s.aboutBody}>
          <Slide triggerOnce>
            <div className={s.aboutImageContainer}>
              <img alt="" src={aboutImage} className={s.aboutImage} />
            </div>
          </Slide>
          <Zoom triggerOnce>
            <ul className={s.aboutItems}>
              {aboutItemKeys.map((key) => (
                <ToggleContent
                  key={key}
                  title={t(`homePage.aboutItems.${key}.title`)}
                  content={t(`homePage.aboutItems.${key}.text`)}
                />
              ))}
            </ul>
          </Zoom>
        </div>
      </section>
      <section className={s.choose}>
        <Effect className={s.chooseEffect} />
        <Zoom duration={1000} triggerOnce>
          <h2 className={s.chooseTitle}>{t("homePage.chooseTitle")}</h2>
        </Zoom>
        <div className={cn(s.line, "line")} />
        <div className={s.chooseItems}>
          <div className={cn(s.chooseItemsRow, s.row2items)}>
            <Fade delay={1000} triggerOnce>
              {getChooseItem("management")}
            </Fade>
            <Fade delay={1500} triggerOnce>
              {getChooseItem("strategy")}
            </Fade>
          </div>
          <div className={cn(s.chooseItemsRow, s.row3items)}>
            <Fade delay={2000} triggerOnce>
              {getChooseItem("deals")}
            </Fade>
            <Fade delay={2500} triggerOnce>
              {getChooseItem("experience")}
            </Fade>
            <Fade delay={3000} triggerOnce>
              {getChooseItem("innovation")}
            </Fade>
          </div>
        </div>
        <div className={s.chooseButtons}>
          <Fade triggerOnce delay={2000} direction="left">
            <Button href={routes.about}>{t("homePage.viewAbout")}</Button>
          </Fade>
          <Fade triggerOnce delay={2500} direction="right">
            <Button href={routes.services}>{t("homePage.viewServices")}</Button>
          </Fade>
        </div>
      </section>
    </>
  );
};

const aboutItemKeys = ["strategy", "campaigns", "analysis", "management"];
