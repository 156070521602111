import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { toast, ToastOptions } from 'react-toastify';
import s from "./contact-form.module.css";

export interface ContactFormValues {
  name: string;
  email: string;
  message: string;
}

export const ContactForm = () => {
  const { t } = useTranslation();
  const methods = useForm<ContactFormValues>();
  const { handleSubmit, reset } = methods;

  const notify = (message: string, context: ToastOptions) => toast(message, context);

  const onSubmit = async (data: ContactFormValues) => {
    // const res = await sendEmail(data);  //TODO: uncomment when backend is ready
    const res = true;
    if (res) {
      notify(t('contactUsForm.submitSuccess'), {type: 'success'});
      reset();
    }
  };

  return (
    <FormProvider {...methods}>
      <form className={s.root} onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={t("contactUsForm.name")}
          name="name"
          validation={{ required: true }}
        />
        <Input
          label={t("contactUsForm.email")}
          name="email"
          validation={{ email: true, required: true }}
        />
        <Input
          label={t("contactUsForm.message")}
          name="message"
          validation={{ required: true }}
        />
        <button className={s.submit} type="submit">
          {t("contactUsForm.submit")}
        </button>
      </form>
    </FormProvider>
  );
};

const Input = ({
  name,
  label,
  validation,
}: {
  name: keyof ContactFormValues;
  label: string;
  validation?: { required?: boolean; email?: boolean };
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<ContactFormValues>();

  const required = !!validation?.required;
  const email = !!validation?.email;

  const error = errors[name];

  return (
    <div className={s.inputRoot}>
      <label htmlFor={name} className={s.label}>
        {label}
        {required ? (
          <>
            {" "}
            <span>*</span>
          </>
        ) : null}
      </label>
      <input
        className={cn(s.input, { [s.inputError]: !!error })}
        id={name}
        {...register(name, {
          required: required && "required",
          pattern: email
            ? {
                value: emailValidationPattern,
                message: "email",
              }
            : undefined,
        })}
      />
      {error ? (
        <div className={s.error}>{t(`formErrors.${error.message}`)}</div>
      ) : null}
    </div>
  );
};

const emailValidationPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
