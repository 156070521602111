import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

interface ToastProviderProps {
  children: React.ReactNode;
}

export default function Toaster({ children }: ToastProviderProps) {

  return (
    <>
      {children}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
      />
    </>
  );
}
